@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");
@import "~antd/dist/antd.css";
@tailwind components;
@tailwind utilities;

:root {
  --text-color: #001529;
  --twitter: #1da1f2;
  --linkedin: #0077b5;
  --github: #333333;
  --website: #1890ff;
  --gmail: #db4437;
  --white: #ffffff;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button-primary {
  @apply bg-button flex justify-center items-center mt-12 px-12 py-6 rounded-normal text-white cursor-pointer;
}

/* Button primary should also have the ability to animate when hovered over and when clicked. The animation should be subtle and not too distracting. The button should also have a shadow when hovered over. */
.button-primary:hover {
  @apply shadow-button;
  /* Should also animate when hovered over
   */
  animation: pulse 1s infinite;
}

.main-section {
  @apply flex flex-col justify-center items-center;
  width: 100%;
  height: calc(100vh - 10rem);
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  color: currentColor !important;
}

.ant-carousel .slick-prev::before {
  color: currentColor !important;
  visibility: hidden;
}

.slick-arrow.slick-prev {
}

.ant-carousel .slick-next::before {
  color: currentColor !important;
  visibility: hidden;
}

.slick-arrow.slick-next {
}

.css-1s2u09g-control {
  background-color: none !important;
  border: none !important;
  @apply rounded-lg bg-primary/[.06] text-primary z-50 cursor-pointer border-none !important;
}

.css-tlfecz-indicatorContainer {
  @apply text-primary !important;
}

.css-14el2xx-placeholder {
  @apply text-primary px-2 py-2 !important;
}
.css-26l3qy-menu {
  z-index: 1000 !important;
  position: absolute !important;
  overflow: visible !important;
}
.ant-carousel .slick-list {
  overflow-y: inherit !important;
  overflow-x: hidden !important;
}

reach-portal {
  z-index: 1000 !important;
  position: absolute;
}
